import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Checkbox, message } from 'antd';
import { Table } from 'reactstrap';
import axios from 'axios';
import user1 from '../../../assets/images/users/user1.jpg';

const ProjectTables = () => {
  const [tableData, setTableData] = useState([]);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setTableData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token]);
  
  const showModal = (employee) => {
    if (!employee.isActive) {
      message.warning('Your account is inactive');
      return;
    }
    
    setEditingEmployee(employee);
    form.setFieldsValue({
      name: employee.name,
      email: employee.email,
      isActive: employee.isActive,
    });
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = form.getFieldsValue();
      await axios.put(`${process.env.REACT_APP_FOS_CLIENT_API}/admin/${editingEmployee.id}`, {
        email: values.email,
        password: editingEmployee.password,  // Assuming password is not being updated
        name: values.name,
        isActive: values.isActive,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTableData((prev) => prev.map(emp => emp.id === editingEmployee.id ? { ...emp, ...values } : emp));
      setIsModalVisible(false);
      setEditingEmployee(null);
    } catch (error) {
      console.error('Error updating employee:', error);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingEmployee(null);
  };

  const handleDelete = async (employee) => {
    try {
      await axios.delete(`${process.env.REACT_APP_FOS_CLIENT_API}/admin/${employee.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTableData(tableData.filter((emp) => emp.id !== employee.id));
    } catch (error) {
      // console.error('Error deleting employee:', error);
      message.error('Failed to delete employee',error);
    }
  };

  return (
    <div>
      <Table className="align-middle" responsive>
        <thead className="bg-dark text-light">
          <tr>
            <th className="px-4">Team Lead</th>
            <th className="px-4">Role</th>
            <th className="px-4">Status</th>
            <th className="px-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((tdata) => (
            <tr key={tdata.id} className="border-top">
              <td>
                <div className="d-flex align-items-center p-2">
                  <img src={user1} className="rounded-circle" alt="avatar" width="45" height="45" />
                  <div className="ms-3">
                    <h5 className="mb-0 fw-medium">{tdata.name}</h5>
                    <span className="text-muted">{tdata.email}</span>
                  </div>
                </div>
              </td>
              <td>{tdata.role}</td>
              <td>
                {tdata?.isActive ? (
                  <span className="p-2 bg-success rounded-circle d-inline-block ms-3" />
                ) : (
                  <span className="p-2 bg-danger rounded-circle d-inline-block ms-3" />
                )}
                <span className="ms-2">{tdata.isActive ? 'Active' : 'Inactive'}</span>
              </td>
              <td>
                <Button type="link" onClick={() => showModal(tdata)}>Edit</Button>
                <Button type="link" onClick={() => handleDelete(tdata)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        title="Edit Employee"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {editingEmployee && (
          <Form
            form={form}
            initialValues={{
              name: editingEmployee.name,
              email: editingEmployee.email,
              role: editingEmployee.role,
              isActive: editingEmployee.isActive,
            }}
            onFinish={handleOk}
          >
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
            {/* <Form.Item name="role" label="Role">
              <Input />
            </Form.Item> */}
            <Form.Item name="isActive" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
            {/* <Form.Item>
              <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item> */}
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default ProjectTables;
