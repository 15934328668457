import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types'; // Import PropTypes
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './ReactBootstrapTable.scss';
import ComponentCard from '../../components/ComponentCard';

function ReactTableSupplier({ data }) {
  function onAfterDeleteRow(rowKeys) {
    alert(`The rowkey you dropped: ${rowKeys}`);
  }

  function afterSearch(searchText, result) {
    console.log(`Your search text is ${searchText}`);
    console.log('Result is:');
    for (let i = 0; i < result.length; i++) {
      console.log(`Fruit: ${result[i].id}, ${result[i].name}, ${result[i].price}`);
    }
  }

  const options = {
    afterDeleteRow: onAfterDeleteRow,
    afterSearch,
  };

  const selectRowProp = {
    mode: 'checkbox',
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
  };

  return (
    <div>
      <Row>
        <h5>Edit Product</h5>

        <Col>
          <div>
            {' '}
            <ComponentCard title="Bootstrap DataTable">
              <BootstrapTable
                striped
                hover
                condensed
                search
                data={data}
                deleteRow
                selectRow={selectRowProp}
                pagination
                options={options}
                cellEdit={cellEditProp}
                tableHeaderClass="mb-0"
              >
                <TableHeaderColumn width="100" dataField="under" isKey>
                  under
                </TableHeaderColumn>
                <TableHeaderColumn width="100" dataField="name">
                  Name
                </TableHeaderColumn>
              </BootstrapTable>
            </ComponentCard>
          </div>
        </Col>
      </Row>
    </div>
  );
}
ReactTableSupplier.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ReactTableSupplier;
