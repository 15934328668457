import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, message } from 'antd';
import Column from 'antd/es/table/Column';
import ComponentCard from '../../components/ComponentCard';

function Pincode() {
    const [form] = Form.useForm();
    const [pincode, setPincode] = useState('');
    const [shipmentAmount, setShipmentAmount] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [editRecord, setEditRecord] = useState(null);
    const [searchText, setSearchText] = useState('');

    const fetchData = () => {
        axios
            .get(`${process.env.REACT_APP_FOS_CLIENT_API}/pincode`)
            .then((res) => {
                setTableData(res.data);
            })
            .catch((err) => {
                console.error(err);
                message.error('Error fetching data');
            });
    };

    useEffect(() => {
        fetchData();
    }, [refresh]);

    const handleSubmit = (values) => {
        console.log('Form Values:', values); // Debugging: Check form values
        const shipmentAmountInt = parseInt(values.shipmentAmount, 10);
        axios
            .post(`${process.env.REACT_APP_FOS_CLIENT_API}/pincode`, {
                pincode: values.pincode,
                shipmentAmount: shipmentAmountInt,
                isAvailable: true,
            })
            .then((res) => {
                console.log('API Response:', res); // Debugging: Check API response
                form.resetFields();
                setPincode('');
                setShipmentAmount(0);
                setRefresh(!refresh);
                message.success('Pincode created successfully');
            })
            .catch((err) => {
                console.log('API Error:', err); // Debugging: Check API error
                message.error('Pincode Already Added');
            });
    };

    const handleDelete = (pincodeId) => {
        axios
            .delete(`${process.env.REACT_APP_FOS_CLIENT_API}/pincode/${pincodeId}`)
            .then(() => {
                setRefresh(!refresh);
                message.success('Pincode deleted successfully');
            })
            .catch((err) => {
                console.log(err);
                message.error('Error deleting pincode');
            });
    };

    const showModal = (record) => {
        setEditRecord(record);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditRecord(null);
    };

    const handleUpdate = (values) => {
        axios
            .put(`${process.env.REACT_APP_FOS_CLIENT_API}/pincode/${editRecord.pincodeId}`, values)
            .then(() => {
                setRefresh(!refresh);
                handleCancel();
                message.success('Pincode updated successfully');
            })
            .catch((err) => {
                console.log(err);
                message.error('Error updating pincode');
            });
    };

    const filteredData = tableData.filter((item) =>
        item.pincode.includes(searchText)
    );

    return (
        <>
            <ComponentCard title="Form Validation">
                <Form form={form} onFinish={handleSubmit}>
                <style>{`
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
      `}</style>
                    <Form.Item
                        label="Pincode"
                        name="pincode"
                        rules={[
                            { 
                                required: true, 
                                message: 'Please input the pincode!' 
                            },
                            { 
                                pattern: /^\d{6}$/, 
                                message: 'Pincode must be exactly 6 digits!' 
                            }
                        ]}
                    >
                        <Input
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            maxLength={6}
                          type="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Amount"
                        name="shipmentAmount"
                        rules={[{ required: true, message: 'Please input the shipment amount!' }]}
                    >
                        <Input
                            value={shipmentAmount}
                            onChange={(e) => setShipmentAmount(e.target.value)}
                            type="number"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button className="button btn-info" type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <div>
                    <Input
                        placeholder="Search by Pincode"
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ marginBottom: 16 }}
                    />
                    <Table dataSource={filteredData} rowKey="pincodeId" pagination={{ pageSize: 10 }}>
                        <Column title="Sequence" key="sequence" render={(text, record, index) => index + 1} />
                        <Column title="Pincode" dataIndex="pincode" key="pincode" />
                        <Column title="Shipment Amount" dataIndex="shipmentAmount" key="shipmentAmount" />
                        <Column
                            title="Action"
                            key="action"
                            render={(record) => (
                                <span>
                                    <Button type="link" onClick={() => showModal(record)}>
                                        Edit
                                    </Button>
                                    <Popconfirm
                                        title="Sure to delete?"
                                        onConfirm={() => handleDelete(record.pincodeId)}
                                    >
                                        <Button type="link" danger>
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                </span>
                            )}
                        />
                    </Table>
                    <Modal
                        title="Edit Pincode"
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        {editRecord && (
                            <Form
                                layout="vertical"
                                initialValues={editRecord}
                                onFinish={handleUpdate}
                            >
                                <Form.Item name="pincode" label="Pincode">
                                    <Input />
                                </Form.Item>
                                <Form.Item name="shipmentAmount" label="Shipment Amount">
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </Modal>
                </div>
            </ComponentCard>
        </>
    );
}

export default Pincode;
