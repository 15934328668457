import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Card, Menu } from 'antd';
// import Tabs from '../ui/Tabs';

const { Item } = Menu;

function Orders() {
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop();

  return (
    <Card >
      <Menu className='mb-4' mode="horizontal" selectedKeys={[currentPath || 'all']}>
      <Item key="all">
          <Link style={{textDecoration:'none'}}  to="all">Processing</Link>
        </Item>
        <Item key="details">
          <Link style={{textDecoration:'none'}}  to="details">Shipping Process</Link>
        </Item>
        <Item key="completed">
          <Link style={{textDecoration:'none'}}  to="completed">Completed</Link>
        </Item>
      
      
      </Menu>
   {/* <Tabs/> */}
      <Outlet />
    </Card>
  );
}

export default Orders;
