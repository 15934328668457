import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Image, message, Modal, Input, Tag, theme, Flex } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './index.css';
import { Row, Col, Button, FormGroup, Label, Tooltip } from 'reactstrap';
import Form from 'react-validation/build/form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentCard from '../../components/ComponentCard';

const tagInputStyle = {
  width: 64,
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: 'top',
};

const UpdateProducts = () => {
    const [names, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [stock, setStock] = useState(true);
    const [priceAfterDiscount, setPriceAfterDiscount] = useState(0);
    const [AdditionalInfo, setAdditionalInfo] = useState(['']);
    const [qty, setQty] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [isNew, setNew] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [keyValuePairs, setKeyValuePairs] = useState([{ key: '', value: '' }]);
    const { token } = theme.useToken();
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const [discountEndTime, setdiscountEndTime] = useState('');
    const [valuedes, setValuedes] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    const [existingImageUrls, setExistingImageUrls] = useState([]);
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [dragging, setDragging] = useState(false);


    const { id } = useParams();
 
console.log(description,"lol");

    const saveKeyValuePairs = () => {
      setKeyValuePairs([...keyValuePairs, { key: '', value: '' }]);
      const stringifiedPairs = JSON.stringify(keyValuePairs);
      setAdditionalInfo([stringifiedPairs]);
    };
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const showModal1 = () => {
      setIsModalOpen1(true);
    };
    const handleOk1 = () => {
      saveKeyValuePairs(AdditionalInfo);
      setIsModalOpen1(false);
    };
    const handleCancel1 = () => {
      setIsModalOpen1(false);
    };

    const handleInputChanges = (index, event) => {
      const { name, value } = event.target;
      const newPairs = keyValuePairs.map((pair, i) =>
        i === index ? { ...pair, [name]: value } : pair,
      );
      setKeyValuePairs(newPairs);
    };

    const handleAddFields = () => {
      setKeyValuePairs([...keyValuePairs, { key: '', value: '' }]);
    };

    const handleRemoveFields = (index) => {
      const values = [...keyValuePairs];
      values.splice(index, 1);
      setKeyValuePairs(values);
    };

    console.log(description);

    useEffect(() => {
      if (inputVisible) {
        inputRef.current?.focus();
      }
    }, [inputVisible]);
    useEffect(() => {
      editInputRef.current?.focus();
    }, [editInputValue]);
    const handleClose = (removedTag) => {
      const newTags = tags.filter((tag) => tag !== removedTag);
      console.log(newTags);
      setTags(newTags);
    };
    const showInput = () => {
      setInputVisible(true);
    };
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
      if (inputValue && !tags.includes(inputValue)) {
        setTags([...tags, inputValue]);
      }
      setInputVisible(false);
      setInputValue('');
    };
    const handleEditInputChange = (e) => {
      setEditInputValue(e.target.value);
    };
    const handleEditInputConfirm = () => {
      const newTags = [...tags];
      newTags[editInputIndex] = editInputValue;
      setTags(newTags);
      setEditInputIndex(-1);
      setEditInputValue('');
    };
    const tagPlusStyle = {
      height: 22,
      background: token.colorBgContainer,
      borderStyle: 'dashed',
    };

    const tokens = localStorage.getItem('token');


    const handleDragOver = (event) => {
      event.preventDefault();
      setDragging(true);
    };

    const handleDragLeave = () => {
      setDragging(false);
    };

    const handleDrop = (event) => {
      event.preventDefault();
      setDragging(false);
      const { files: droppedFiles } = event.dataTransfer;
      if (droppedFiles.length) {
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        console.log(droppedFiles);
      }
    };


    const handleFileChange = (event) => {
      const { files: selectedFiles } = event.target;
      if (selectedFiles.length) {
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        console.log(selectedFiles);
      }
    };

    const uploadFiles = async () => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });


      try {
        const response = await axios.post(
          `${process.env.REACT_APP_FOS_CLIENT_API}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return response.data.urls;
      } catch (error) {
        console.error('Error uploading files:', error);
        throw error;
      }
    };


    const navigateUpdate = () => {
      navigate('/ecom/productDisplay');
    }


    const handleSubmit = async (e) => {
      e.preventDefault();

      try {

        const imageUrls = await uploadFiles();
        console.log(imageUrls, "imgurl");
        const response = await axios.put(
          `${process.env.REACT_APP_FOS_CLIENT_API}/product/${id}`,
          {
            description: valuedes,
            name: names,
            NewProduct: isNew,
            price: parseInt(price, 10),
            stock,
            imageUrl: [...existingImageUrls, ...imageUrls], // Ensure this is an array of strings
            tag: tags, // Ensure this is an array of strings if needed
            AdditionalInfo,
            priceAfterDiscount: parseInt(priceAfterDiscount, 10),
            qty: parseInt(qty, 10),
            discount: parseInt(discount, 10),
            discountEndTime,
            Variants: [], // Make sure this is the correct format
          },
          {
            headers: {
              Authorization: `Bearer ${tokens}`, // Set the authorization header
              'Content-Type': 'application/json', // Optional: specify content type
            },
          }
        );

        // Notify success
        message.success('Product updated successfully');
        navigate('/ecom/productDisplay');
        console.log(response);

        // Clear form fields
        setName('');
        setDescription('');
        setPrice(0);
        setStock(true);
        setPriceAfterDiscount(0);
        setTags(['']);
        setAdditionalInfo(['']);
        setQty(0);
        setDiscount(0);
        setNew(false);
        setFiles([]);
        setValuedes('');
      } catch (error) {
        // Handle error
        message.error('Name Already Exists');
        console.error('Error updating product:', error);
      }
    };


    const handleChange = (content) => {
      setValuedes(content);
    };

    console.log(valuedes, 'values');

    const modules = {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }],
        ['clean'],
      ],
    };

    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'color',
      'background',
      'link',
      'image',
      'video',
    ];

    const quillStyles = {
      height: '200px',
      width: '100%',
    };


    useEffect(() => {
      const fetchProductDetails = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/product/${id}`);
          const product = response.data;
          setDescription(product.description)
          setName(product.name);
          setPrice(product.price);
          setStock(product.stock);
          setPriceAfterDiscount(product.priceAfterDiscount);
          setTags(product.tags);
          setAdditionalInfo(product.additionalInfo);
  
          setTags(product.tags);
          setNew(product.NewProduct);
          setExistingImageUrls(product.imageUrl);
          setTags(product.tag);
          setDiscount(product.discount)
          setdiscountEndTime(product.discountEndTime)
          setValuedes(product.description)
          setQty(product.qty)
          if (product.AdditionalInfo && product.AdditionalInfo.length > 0) {
            const parsedAdditionalInfo = product.AdditionalInfo.map(info => {
              try {
                return JSON.parse(info);
              } catch (error) {
                console.error("Invalid JSON in AdditionalInfo:", info, error);
                return null; // Return null for invalid JSON
              }
            }).filter(info => info !== null); // Filter out null values
          
            setKeyValuePairs(parsedAdditionalInfo.flat());
          } else {
            setAdditionalInfo(['']);
            setKeyValuePairs([{ key: '', value: '' }]);
          }
          
        } catch (error) {
          message.error('Error fetching product details');
          console.log(error);
        }
      };

      fetchProductDetails();
    }, [id]);
    const handleRemoveImage = (index) => {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };


    const removeFile = (index) => {
      setExistingImageUrls ((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

  return (
    <>
      <Row>
        <Col sm="12">             <style>{`
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
      `}</style>
          <ComponentCard title="Form Validation">
            <Form onSubmit={handleSubmit}>
              <div className="border px-2 rounded row">
                <div className="row py-2">
                  <FormGroup className="col-5">
                    <Label htmlFor="name">Product Name:</Label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      className="form-control"
                      value={names}
                      required
                      placeholder="Product name"
                      max={500}
                    />
                  </FormGroup>
                  <FormGroup className="col-5 d-flex align-item-start justify-content-start " check>
                    <div check>
                      <div style={{ marginTop: '28px' }}>
                        <Tag color="#108ee9">NEW</Tag>
                      </div>
                    </div>

                    <input
                      style={{ width: '5%' }}
                      type="checkbox"
                      onChange={(e) => setNew(e.target.checked)}
                      checked={isNew}
                    />
                  </FormGroup>
                </div>
                <div
                  className={`file-upload ${dragging ? 'dragging' : ''}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/*" 
                    multiple
                  />
                  <label htmlFor="fileInput">
                    <div className="upload-icon">
                      <i className="fa-solid fa-cloud-arrow-up"></i>{' '}
                      {/* Simple representation of an icon */}
                    </div>
                    <p>Drag and drop your file here or click to select a file!</p>
                  </label>
                </div>

                <div className="mt-3">
                  {existingImageUrls.map((url, index) => (
                    <div style={{ position: 'relative',display:'inline' }}>
                      <Image
                        style={{border:'5px white solid'}}
                        width={200}
                        src={url}
                        alt={`existing preview ${index}`}
                      />
                          <CloseCircleOutlined
                          style={{ position: 'absolute',left:'3px',cursor:'pointer'}}
                          type="primary"
                          className='fs-4 mt-2'
                          danger
                          onClick={() => removeFile(index)}
                        />
                    </div>
                  ))}
                  {files.length > 0 &&
                    files.map((file, index) => (
                      <div style={{ position: 'relative',display:'inline' }}>
                        <Image
                          width={200}
                          className='ms-2'
                          src={URL.createObjectURL(file)}
                          alt={`preview ${index}`}
                        />
                        <CloseCircleOutlined
                          style={{ position: 'absolute',left:'3px',cursor:'pointer'}}
                          type="primary"
                          className='fs-4 mt-2'
                          danger
                          onClick={() => handleRemoveImage(index)}
                        />
                        
                      </div>

                    ))}

                </div>
              </div>

              <div className="row mt-4">
                <div className="col-6 border rounded p-3">
                  <h4>Product Information</h4>
                  <hr />
                  <div>
                    {' '}
                    <Button className="button btn-info" onClick={showModal1}>
                      ADD ADDITIONAL INFO
                    </Button>
                    <Modal
                      style={{ overflow: 'hidden' }}
                      className="w-75"
                      title="ADD INFO"
                      open={isModalOpen1}
                      onOk={handleOk1}
                      onCancel={handleCancel1}
                    >
                      <div className="mt-3 p-3">
                        {keyValuePairs.map((pair, index) => (
                          <FormGroup>
                            <div style={{ position: 'relative' }} className="row border pb-3">
                              <div className="mr-2 col-6">
                                <Label htmlFor={`key-${index}`}>Key:</Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`key-${index}`}
                                  name="key"
                                  value={pair.key}
                                  onChange={(e) => handleInputChanges(index, e)}
                                  required
                                  max={200}
                                />
                              </div>
                              <div className="mr-2 col-6">
                                <Label htmlFor={`value-${index}`}>Value:</Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`value-${index}`}
                                  name="value"
                                  value={pair.value}
                                  onChange={(e) => handleInputChanges(index, e)}
                                  required
                                  max={200}
                                />
                              </div>

                              <CloseCircleOutlined
                                style={{
                                  position: 'absolute',
                                  left: '98%',
                                  fontSize: '20px',
                                  top: '-6%',
                                  color: 'red',
                                }}
                                onClick={() => handleRemoveFields(index)}
                              />
                            </div>
                          </FormGroup>
                        ))}

                        <Button color="primary" onClick={handleAddFields}>
                          +
                        </Button>
                      </div>
                    </Modal>
                  </div>
                </div>
                <div className="col-6">
                  <div className="col-12 float-end rounded border p-3">
                    <h4>Product Pricing</h4>
                    <hr />
                    <FormGroup>
                      <Label htmlFor="price">Price:</Label>
                      <input
                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                        type="number"
                        step="0.01"
                        className="form-control"
                        value={price}
                        max={5000000}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="price_after_discount">Price After Discount:</Label>
                      <input
                        onChange={(e) => setPriceAfterDiscount(parseFloat(e.target.value))}
                        type="number"
                        step="0.01"
                        className="form-control"
                        value={priceAfterDiscount}
                        required
                        max={5000000}
                      />
                    </FormGroup>
                    <div>
                      {' '}
                      <Button className="button btn-info" onClick={showModal}>
                        ADD DISCOUNT %
                      </Button>
                      <Modal
                        title="ADD DISCOUNT"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                      >
                        <FormGroup>
                          <Label htmlFor="discount">Discount:</Label>
                          <input
                            onChange={(e) => setDiscount(parseFloat(e.target.value))}
                            type="number"
                            className="form-control"
                            value={discount}
                            required
                            max={100}
                            min={0}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="discount">Discount Ends:</Label>
                          <input
                            onChange={(e) => setdiscountEndTime(e.target.value)}
                            type="date"
                            step="0.01"
                            className="form-control"
                            value={discountEndTime}
                            required
                          />
                        </FormGroup>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="p-2 border mb-4">
                  <Label>Add Tags:</Label>

                  <Flex className="mt-2" gap="4px 0" wrap>
                    {tags?.map((tag, index) => {
                      if (editInputIndex === index) {
                        return (
                          <Input
                            ref={editInputRef}
                            key={tag}
                            size="small"
                            style={tagInputStyle}
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                            max={300}
                          />
                        );
                      }
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          color="processing"
                          closable={index !== 0}
                          style={{
                            userSelect: 'none',
                          }}
                          onClose={() => handleClose(tag)}
                        >
                          <span
                            onDoubleClick={(e) => {
                              if (index !== 0) {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                              }
                            }}
                          >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                          </span>
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                    {inputVisible ? (
                      <Input
                        ref={inputRef}
                        type="text"
                        size="large"
                        max={300}
                        style={tagInputStyle}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                      />
                    ) : (
                      <Tag
                        color="processing"
                        style={tagPlusStyle}
                        icon={<PlusOutlined />}
                        onClick={showInput}
                      >
                        New Tag
                      </Tag>
                    )}
                  </Flex>
                </div>

                <FormGroup>
                  <Label htmlFor="qty">Quantity:</Label>
                  <input
                    onChange={(e) => setQty(e.target.value)}
                    type="number"
                    className="form-control"
                    value={qty}
                    required
                    max={5000}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="description">Description:</Label>
                  <ReactQuill
                    value={valuedes}
                    modules={modules}
                    formats={formats}
                    style={quillStyles}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup className='mt-5'>
                  <Button className="button btn-info" type="submit">
                    Update
                  </Button>
                  <Button className="button btn-danger ms-2" onClick={navigateUpdate} type="submit">
                    Cancel
                  </Button>
                </FormGroup>
              </div>
            </Form>
          </ComponentCard>
        </Col>
      </Row>
    </>
  );
};

export default UpdateProducts;
