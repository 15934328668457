import React, { useEffect, useState } from 'react';
import { Avatar, List, Skeleton, Select, Modal, Image, Pagination, Input } from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ProductDetails from '../form-validation/ProductDetails';

const { Option } = Select;
const { Search } = Input;

const App = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('token');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem('http://localhost:3000');
    setUserRole(role);
  }, []);

  const fetchTableData = async () => {
    const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/product`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    const res = await response.json();
    setInitLoading(false);
    setData(res);
    setList(res);
    const uniqueCategories = [...new Set(res.map(item => item.category[0]))];
    setCategories(uniqueCategories);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    const filteredList = value ? data.filter(item => item.category.includes(value)) : data;
    setList(filteredList);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    const filteredList = value ? data.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : data;
    setList(filteredList);
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    handleCategoryChange(selectedCategory);
  }, [data, selectedCategory]);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [data, searchQuery]);

  const handleView = (product) => {
    setSelectedProduct(product);
    setViewModalVisible(true);
  };

  const handleDelete = (product) => {
    setSelectedProduct(product);
    setDeleteModalVisible(true);
  };

  const handleEdit = (product) => {
 
      navigate(`/ecom/updateproduct/${product.id}`);
  };

  const confirmDelete = async () => {
    try {
      await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/product/${selectedProduct.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setData(data.filter(item => item.id !== selectedProduct.id));
      setList(list.filter(item => item.id !== selectedProduct.id));
      setDeleteModalVisible(false);
      setSelectedProduct(null);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const parseAdditionalInfo = (additionalInfoString) => {
    try {
      const additionalInfo = JSON.parse(additionalInfoString);
      return additionalInfo.map((info) => (
        <p key={info.key}>
          <strong>{info.key}: </strong> {info.value}
        </p>
      ));
    } catch (error) {
      console.error('Error parsing AdditionalInfo:', error);
      return null;
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  const paginatedList = list.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Select
          placeholder="Select a category"
          style={{ width: 200 }}
          onChange={handleCategoryChange}
          value={selectedCategory}
          allowClear
        >
          {categories.map(category => (
            <Option key={category} value={category}>{category}</Option>
          ))}
        </Select> 
        <Search
          placeholder="Search products"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
        />
      </div>
      <List
        className="demo-loadmore-list bg-light p-2"
        loading={initLoading}
        itemLayout="horizontal"
        dataSource={paginatedList}
        renderItem={(item) => (
          <List.Item
            actions={[
              <EyeOutlined key="view" onClick={() => handleView(item)} />,
              // <EditOutlined key="edit" onClick={() => handleEdit(item)} />,
              userRole !=='EMPLOYEE' && <EditOutlined key='edit' onClick={()=>handleEdit(item)}/>,
              userRole !== 'EMPLOYEE' && <DeleteOutlined key="delete" onClick={() => handleDelete(item)} />
            ].filter(Boolean)}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={<Avatar className='border' src={item.imageUrl[0]} />}
                title={<a onClick={() => handleView(item)}><strong>{item.name}</strong></a>}
                description={`Price: $${item.price} | Discount: ${item.discount}%`}
              />
              <div>{formatDateTime(item.ProductCreateTime)}</div>
            </Skeleton>
          </List.Item>
        )}
      />

      <Pagination
        current={currentPage}
        pageSize={itemsPerPage}
        total={list.length}
        onChange={handlePageChange}
        style={{ marginTop: 20, textAlign: 'center' }}
      />

      <Modal
        className='w-75'
        visible={viewModalVisible}
        title="Product Details"
        onCancel={() => setViewModalVisible(false)}
        footer={null}
      >
        {selectedProduct && (
          <div className='row'>
            <div className='col-lg-4'>
              <Image src={selectedProduct.imageUrl[0]} />
            </div>
            <div className='col-lg-8'>
              <p><strong>Name:</strong> {selectedProduct.name}</p>
              <p><strong>Price:</strong> ${selectedProduct.price}</p>
              <p><strong>Discount:</strong> {selectedProduct.discount}%</p>
              <p><strong>Price After Discount:</strong> ${selectedProduct.priceAfterDiscount}</p>
              <p><strong>Description:</strong> <ProductDetails ProductDetail={selectedProduct.description} /></p>
              <p><strong>Stock:</strong> {selectedProduct.stock ? 'In Stock' : 'Out of Stock'}</p>
              <p><strong>Sale Count:</strong> {selectedProduct.saleCount}</p>
              <p><strong>New Product:</strong> {selectedProduct.NewProduct ? 'Yes' : 'No'}</p>
              <p><strong>Quantity:</strong> {selectedProduct.qty}</p>
              <p><strong>Tags:</strong> {selectedProduct.tag.join(', ')}</p>
              <p><strong>Categories:</strong> {selectedProduct.category.join(', ')}</p>
              <p><strong>Discount End Time:</strong> {selectedProduct.discountEndTime}</p>
              <p><strong>Additional Info:</strong> {parseAdditionalInfo(selectedProduct.AdditionalInfo)}</p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        className='w-25'
        visible={deleteModalVisible}
        title="Confirm Delete"
        onCancel={() => setDeleteModalVisible(false)}
        onOk={confirmDelete}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        Are you sure you want to delete this product?
      </Modal>
    </div>
  );
};

export default App;
