import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, Card, Rate, Button, message, Collapse, Modal } from 'antd';
import moment from 'moment';

const { Meta } = Card;
const { Panel } = Collapse;
const { confirm } = Modal;

function Reviews() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/product`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, []);

  const deleteReview = async (reviewId, productId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_FOS_CLIENT_API}/reviews/${reviewId}`);
      setProducts(products.map(product => 
        product.id === productId 
          ? { ...product, Reviews: product.Reviews.filter(review => review.reviewId !== reviewId) }
          : product
      ));
      message.success('Review deleted successfully');
    } catch (error) {
      console.error('Error deleting review:', error);
      message.error('Failed to delete review');
    }
  };

  const showDeleteConfirm = (reviewId, productId) => {
    confirm({
      title: 'Are you sure you want to delete this review?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteReview(reviewId, productId);
      },
    });
  };

  return (
    <div style={{ padding: '20px' }}>
      <Collapse accordion>
        {products.filter(product => product.Reviews.length > 0).map(product => (
          <Panel header={product.name} key={product.id}>
            <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={product.Reviews}
              renderItem={review => (
                <List.Item>
                  <Card
                    actions={[
                      <Button type="danger" onClick={() => showDeleteConfirm(review.reviewId, product.id)}>Delete</Button>
                    ]}
                  >
                    <Meta
                      title={
                        <div>
                          <Rate disabled defaultValue={review.rating} />
                        </div>
                      }
                      description={
                        <div>
                          <p>{review.comment}</p>
                          <p>{moment(review.reviewDate).format('YYYY-MM-DD HH:mm')}</p>
                          <p>User Email: {review.User?.email || 'N/A'}</p>
                        </div>
                      }
                    />
                  </Card>
                </List.Item>
              )}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default Reviews;
