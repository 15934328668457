import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        totalQty :0
    },
    reducers: {
        setProducts: (state,action) => {
            state.products = action.payload;
            state.totalQty = action.payload.reduce((total, product) => total + product.qty, 0);
        },
    },
});

export const{setProducts,setTotalQty}=productSlice.actions;
export default productSlice.reducer;