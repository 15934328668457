import { Input, Space, Table, Button, Modal, Form, Select, message } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ProductDetails from '../form-validation/ProductDetails';
import Invoice from './Invoice';

const { Option } = Select;

function OrderDetails() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [editForm] = Form.useForm();
  const invoiceRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders`);
        const acceptedOrders = response.data
          .filter((order) => order.status === 'OrderAccepted')
          .sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
        setOrders(acceptedOrders);
        setFilteredOrders(acceptedOrders);
      } catch (error) {
        console.error('Error fetching orders data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  const handleSearch = (value) => {
    setSearchText(value);
    const lowercasedValue = value.toLowerCase();
    const filtered = orders.filter(
      (order) =>
        order.ShippingAddress.email.toLowerCase().includes(lowercasedValue) ||
        order.ShippingAddress.Phone.includes(lowercasedValue),
    );
    setFilteredOrders(filtered);
  };

  const showModal = async (orderId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders/${orderId}`);
      setSelectedOrder(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const showModalEdit = async (orderId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders/${orderId}`);
      setSelectedOrder(response.data);
      setIsModalEditVisible(true);
      editForm.setFieldsValue(response.data);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOkEdit = async () => {
    try {
      const values = editForm.getFieldsValue();

      const updatedValues = { ...values, status: "OrderDelivery" };
      await axios.put(
        `${process.env.REACT_APP_FOS_CLIENT_API}/orders/${selectedOrder.orderId}`,
        updatedValues,
      );
      message.success('Order updated successfully');
      setIsModalEditVisible(false);
      // Update orders data
      const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders`);
      setOrders(response.data.filter((order) => order.status === 'delivery'));
      setFilteredOrders(response.data.filter((order) => order.status === 'delivery'));
    } catch (error) {
      console.error('Error updating order:', error);
      message.error('Failed to update order');
    }
  };

  const handleCancelEdit = () => {
    setIsModalEditVisible(false);
  };

  // const handleCancelOrder = async (orderId) => {
  //   try {
  //     await axios.put(`${process.env.REACT_APP_FOS_CLIENT_API}/orders/${orderId}`, {
  //       status: 'cancelled',
  //     });
  //     message.success('Order cancelled successfully');
  //     // Update orders data
  //     const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders`);
  //     setOrders(response.data.filter((order) => order.status === 'delivery'));
  //     setFilteredOrders(response.data.filter((order) => order.status === 'delivery'));
  //     setIsModalEditVisible(false);
  //   } catch (error) {
  //     console.error('Error cancelling order:', error);
  //     message.error('Failed to cancel order');
  //   }
  // };

  // const showCancelConfirm = (orderId) => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to cancel this order?',
  //     onOk: () => handleCancelOrder(orderId),
  //     okText: 'Yes',
  //     okButtonProps: { danger: true, type: 'primary' },
  //     cancelText: 'No',
  //   });
  // };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: ['User', 'name'],
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: ['ShippingAddress', 'Phone'],
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: ['ShippingAddress', 'email'],
      key: 'email',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentmtd',
      key: 'paymentmtd',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Button type="primary" icon={<EyeOutlined />} onClick={() => showModal(record.orderId)} />
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => showModalEdit(record.orderId)}
          />
          {/* <Button
            type="danger"
            icon={<CloseCircleOutlined />}
            onClick={() => showCancelConfirm(record.orderId)}
          /> */}
        </Space>
      ),
    },
  ];

  const columnst = [
    {
      title: 'Product Name',
      dataIndex: ['Product', 'name'],
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: ['Product', 'description'],
      key: 'description',
      render: (text) => <ProductDetails ProductDetail={text} />,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Image',
      dataIndex: ['Product', 'imageUrl'],
      key: 'image',
      render: (imageUrl, record) => (
        <img src={imageUrl[0]} alt={record.Product.name} style={{ width: '100px' }} />
      ),
    },
  ];

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Input.Search
          placeholder="Search by email or phone"
          enterButton
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Space>
      <Table dataSource={filteredOrders} columns={columns} className="rounded" rowKey="orderId" />
      <Modal
        className="w-75"
        title="Order Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedOrder && (
          <div>
            <p>
              <strong>Email:</strong> {selectedOrder.ShippingAddress.email}
            </p>
            <p>
              <strong>Address:</strong> {selectedOrder.ShippingAddress.address}
            </p>
            <p>
              <strong>Phone:</strong> {selectedOrder.ShippingAddress.Phone}
            </p>
            <p>
              <strong>Total Amount:</strong> {selectedOrder.totalAmount}
            </p>
            <p>
              <strong>Payment Method:</strong> {selectedOrder.paymentmtd}
            </p>
            <p>
              <strong>Order Date:</strong> {new Date(selectedOrder.orderDate).toLocaleString()}
            </p>

            <h3>Order Items:</h3>
            {/* <List
              dataSource={selectedOrder.OrderItems}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.Product.name}
                    description={<ProductDetails ProductDetail={item.Product.description} />}
                  />
                  <div>
                    <p>
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                    <p>
                      <strong>Price:</strong> {item.price}
                    </p>
                    <img
                      src={item.Product.imageUrl[0]}
                      alt={item.Product.name}
                      style={{ width: '100px' }}
                    />
                  </div>
                </List.Item>
              )}
            /> */}
            <Table
              dataSource={selectedOrder.OrderItems}
              columns={columnst}
              rowKey={(record) => record.id}
              pagination={false}
            />
          </div>
        )}

        {selectedOrder && (
          <div className="mt-3 text-end">
            <div style={{ display: 'none' }}>
              <Invoice ref={invoiceRef} order={selectedOrder} />
            </div>
            <Button type="primary" onClick={handlePrint}>
              Download Invoice
            </Button>
            <Button className="ms-2">Send to mail</Button>
          </div>
        )}
      </Modal>
      <Modal
        className="w-75"
        title=" Order Summary"
        visible={isModalEditVisible}
        onOk={handleOkEdit}
        onCancel={handleCancelEdit}
      >
        {selectedOrder && (
          <Form className="row" form={editForm} layout="vertical">
            <div className="col-6">
              <Form.Item name="totalAmount" label="Total Amount">
                <Input disabled />
              </Form.Item>
              <Form.Item name="paymentmtd" label="Payment Method">
                <Select disabled>
                  <Option value="PayPal">PayPal</Option>
                  <Option value="CreditCard">Credit Card</Option>
                  <Option value="BankTransfer">Bank Transfer</Option>
                </Select>
              </Form.Item>
              <Form.Item name="status" label="Status">
                <Select disabled>
                  <Option value="pending">Pending</Option>
                  <Option value="delivery">Delivery</Option>
                  <Option value="cancelled">Cancelled</Option>
                </Select>
              </Form.Item>
              <Form.Item name="orderDate" label="orderDate">
                <Input disabled />
              </Form.Item>
              <Form.Item name="tracking" label="Tracking">
                <Input />
              </Form.Item>
              <Form.Item name="shippingdate" label="Shippingdate">
                <Input type="date" />
              </Form.Item>
            </div>
            <div className="col-6">
              <Form.Item name="carrier" label="Carrier">
                <Input />
              </Form.Item>
              <Form.Item name="sessionId" label="SessionId">
                <Input />
              </Form.Item>
              <div className="mt-2">
                <div className="border mt-3 p-3">
                  <div>
                    <strong>Order Status :</strong> {selectedOrder.status}{' '}
                  </div>
                  <p>
                    {' '}
                    <strong>Payment method : </strong>
                    {selectedOrder.paymentmtd}
                  </p>
                </div>
                <div className="btn btn-dark w-100 mt-2">
                  Total Amount $ {selectedOrder.totalAmount}
                </div>
                {/* <div
                  onClick={() => handleCancelOrder(selectedOrder.orderId)}
                  className="btn w-100 text-white btn-danger mt-2"
                >
                  Order Cancel
                </div> */}
              </div>
            </div>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default OrderDetails;
