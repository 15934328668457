import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import DOMPurify from 'dompurify';

const ProductDetails = ({ ProductDetail }) => {
  const sanitizedDescription = DOMPurify.sanitize(ProductDetail);


  return (
    <div>
      <p dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
    </div>
  );
};

// Define prop types for the component
ProductDetails.propTypes = {
  ProductDetail: PropTypes.string.isRequired
};

export default ProductDetails;
