
import { createSlice } from '@reduxjs/toolkit';

const subcat = createSlice({
  name: 'subcat',
  initialState: {
    subcat: [],
  },
  reducers: {
    setSubCat: (state, action) => {
      state.subcat = action.payload;
    },
  },
});

export const { setSubCat } = subcat.actions;
export default subcat.reducer;