import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Button, FormGroup, Label, Modal, Input } from 'reactstrap';
import Form from 'react-validation/build/form';
import { message, Popconfirm } from 'antd';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ComponentCard from '../../components/ComponentCard';
import { setSubCat } from '../../store/Slices/sub-cat';

function SubCategory() {
  const [SubcategoryName, setSubCatName] = useState('');
  const [categoryNameId, setCatNameId] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');
  const [subdata, setsubdta] = useState([]);
  const [userRole, setUserRole] = useState('');

  const subcatdata = useSelector((state) => state.subcat.subcat);
  const dispatch = useDispatch();

  useEffect(() => {
    const role = localStorage.getItem('http://localhost:3000');
    setUserRole(role);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/category`)
      .then((res) => {
        setsubdta(res.data);
      })
      .catch((err) => {
        console.error(err);
        alert('Error fetching data');
      });
  }, [refresh]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/subcategories`)
      .then((res) => {
        dispatch(setSubCat(res.data));
      })
      .catch((err) => {
        console.error(err);
        alert('Error fetching data');
      });
  }, [refresh]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_FOS_CLIENT_API}/subcategories`, { categoryId: categoryNameId, subcategoryName: SubcategoryName })
      .then((res) => {
        setRefresh(!refresh);
        console.log(res);
        setSubCatName('');
        setCatNameId('');
        message.success('Category created successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditSubmit = () => {
    if (!editCategoryId) {
      console.error('Edit Category ID is not set');
      return;
    }
    axios
      .put(`${process.env.REACT_APP_FOS_CLIENT_API}/subcategories/${editCategoryId}`, {
        subcategoryName: editCategoryName,
      })
      .then((res) => {
        dispatch(setSubCat(subcatdata.map((category) =>
          category.subcategoryId === editCategoryId
            ? { ...category, subcategoryName: editCategoryName }
            : category
        )));
        console.log('Category updated:', res);
        setEditModalVisible(false);
        message.success('Category updated successfully');
      })
      .catch((err) => {
        console.error('Error updating category:', err);
      });
  };

  const handleDelete = (subcategoryId) => {
    if (!subcategoryId) {
      console.error('Delete Category ID is not set');
      return;
    }
    axios
      .delete(`${process.env.REACT_APP_FOS_CLIENT_API}/subcategories/${subcategoryId}`)
      .then((res) => {
        dispatch(setSubCat(subcatdata.filter((category) => category.subcategoryId !== subcategoryId)));
        console.log('Category deleted:', res);
        message.success('Category deleted successfully');
      })
      .catch((err) => {
        message.error('Name Already Exists');
        console.error('Error deleting category:', err);
      });
  };

  const openEditModal = (category) => {
    if (!category || !category.subcategoryId) {
      console.error('Invalid category data for editing:', category);
      return;
    }
    setEditCategoryId(category.subcategoryId);
    setEditCategoryName(category.subcategoryName);
    setEditModalVisible(true);
  };

  const numberFormatter = (cell, row, enumObject, index) => index + 1;

  return (
    <>
      <Row>
        <Col sm="12">
          <ComponentCard title="Form Validation">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label className="control-Label" htmlFor="category">
                  Subcategory Name :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={(e) => setSubCatName(e.target.value)}
                    type="text"
                    className="form-control"
                    value={SubcategoryName}
                    maxLength={30}
                    required
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="category">
                  Category:
                </Label>
                <div className="mb-1">
                  <select
                    onChange={(e) => setCatNameId(e.target.value)}
                    className="form-control"
                    value={categoryNameId}
                    required
                  >
                    <option value="">Select a category</option>
                    {subdata?.map((category) => (
                      <option key={category.categoryId} value={category.categoryId}>
                        {category.categoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </FormGroup>

              <FormGroup>
                <Button className="button btn-info" type="submit">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </ComponentCard>

          <ComponentCard title="Bootstrap DataTable">
            <BootstrapTable
              hover
              condensed
              search
              data={subcatdata}
              pagination
              tableHeaderClass="mb-0"
            >
              <TableHeaderColumn
                width="100"
                dataField="subcategoryId"
                isKey
                dataFormat={numberFormatter}
                className="text-dark bg-light"
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn
                className="text-dark bg-light"
                width="100"
                dataField="subcategoryName"
              >
                Name
              </TableHeaderColumn>
              <TableHeaderColumn
                className="text-dark bg-light"
                width="100"
                dataField="actions"
                dataFormat={(cell, row) => (
                  <>
                    <Button className="btn-sm btn-warning" onClick={() => openEditModal(row)}>
                      Edit
                    </Button>
                    {userRole !== 'EMPLOYEE' && (
                      <Popconfirm
                        title="Are you sure to delete this category?"
                        onConfirm={() => handleDelete(row.subcategoryId)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="btn-sm btn-danger" style={{ marginLeft: '10px' }}>
                          Delete
                        </Button>
                      </Popconfirm>
                    )}
                  </>
                )}
              >
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          </ComponentCard>

          <Modal isOpen={editModalVisible} toggle={() => setEditModalVisible(!editModalVisible)}>
            <div className="modal-header">
              <h5 className="modal-title">Edit Category</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => setEditModalVisible(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FormGroup>
                <Label className="control-Label" htmlFor="category">
                  Subcategory Name :
                </Label>
                <div className="mb-1">
                  <Input
                    onChange={(e) => setEditCategoryName(e.target.value)}
                    type="text"
                    className="form-control"
                    value={editCategoryName}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button className="button btn-info" onClick={handleEditSubmit}>
                Save
              </Button>
              <Button className="button btn-secondary" onClick={() => setEditModalVisible(false)}>
                Cancel
              </Button>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default SubCategory;
