import { configureStore } from '@reduxjs/toolkit';
import NotesReducer from './apps/notes/NotesSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import ChatsReducer from './apps/chat/ChatSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';
import productsSlice from './Slices/products-slice';
import brandReducer from './Slices/brand-slice';
import usersReducer from './Slices/users-slice';
import subcatReducer from './Slices/sub-cat';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    chatReducer: ChatsReducer,
    contactsReducer: ContactsReducer,
    emailReducer: EmailReducer,
    ticketReducer: TicketReducer,
    product:productsSlice,
    brand: brandReducer,
    users: usersReducer,
    subcat: subcatReducer,
  },
});

export default store;
