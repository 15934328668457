import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import Form from 'react-validation/build/form';
import axios from 'axios';
import ComponentCard from '../../components/ComponentCard';
import ReactTableSupplier from '../tables/ReactTableSupplier';

const Supplier = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(
    () => {
      axios
        .get(`${process.env.REACT_APP_FOS_CLIENT_API}/supplier`)
        .then((res) => {
          setData(res.data);
          console.log(res);
        })
        .catch((err) => {
          console.error('Error fetching brand data:', err);
        });
    },
    [refresh],
    [],
  );

  const [formData, setFormData] = useState({
    supid: '',
    name: '',
    printname: '',
    under: '',
    address: '',
    city: '',
    state: '',
    statecode: '',
    gstno: '',
    contactno: '',
    extrainfo1: '',
    extrainfo2: '',
    notes: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_FOS_CLIENT_API}/supplier`, formData);
      console.log('Data added successfully');
      setRefresh(!refresh);
      setFormData({
        supid: '',
        name: '',
        printname: '',
        under: '',
        address: '',
        city: '',
        state: '',
        statecode: '',
        gstno: '',
        contactno: '',
        extrainfo1: '',
        extrainfo2: '',
        notes: '',
      });
    } catch (error) {
      console.error('Error adding data:', error);
    }
  };

  return (
    <>
      <Row>
        <Col sm="12">
          <ComponentCard title="Form Validation">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Supplierid :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.supid}
                    type="text"
                    className="form-control"
                    name="supid"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Name :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.name}
                    type="text"
                    className="form-control"
                    name="name"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Prin Name :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.printname}
                    type="text"
                    className="form-control"
                    name="printname"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Under :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.under}
                    type="text"
                    className="form-control"
                    name="under"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Address :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.address}
                    type="text"
                    className="form-control"
                    name="address"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  City :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.city}
                    type="text"
                    className="form-control"
                    name="city"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  State :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.state}
                    type="text"
                    name="state"
                    className="form-control"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  State Code :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.statecode}
                    name="statecode"
                    type="number"
                    className="form-control"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  GST No :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.gstno}
                    type="number"
                    className="form-control"
                    name="gstno"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Contact No :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.contactno}
                    type="number"
                    name="contactno"
                    className="form-control"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Extra Info 1 :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={handleChange}
                    value={formData.extrainfo1}
                    type="text"
                    name="extrainfo1"
                    className="form-control"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Extra Info 2 :
                </Label>
                <div className="mb-1">
                  <input
                    name="extrainfo2"
                    onChange={handleChange}
                    value={formData.extrainfo2}
                    type="text"
                    className="form-control"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Notes :
                </Label>
                <div className="mb-1">
                  <input
                    name="notes"
                    onChange={handleChange}
                    value={formData.notes}
                    type="text"
                    className="form-control"
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <Button className="button btn-info" type="submit">
                  Submit
                </Button>
              </FormGroup>
            </Form>

            <hr />
            <ReactTableSupplier data={data} />
          </ComponentCard>
        </Col>
      </Row>
    </>
  );
};

export default Supplier;
