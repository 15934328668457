import React, { Suspense, useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import LoginFormik from './views/auth/LoginFormik'; // Import LoginFormik component

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check local storage to see if the user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  // Existing code
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        {!isLoggedIn ? (
          <LoginFormik setIsLoggedIn={setIsLoggedIn} />
        ) : (
          routing
        )}
      </div>
    </Suspense>
  );
};

export default App;
