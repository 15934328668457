import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Typography, Card, Divider, Row, Col } from 'antd';
import 'antd/dist/reset.css';

const { Title, Text } = Typography;

const Invoice = React.forwardRef(({ order }, ref) => {
  const columns = [
    {
      title: 'Service',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Rate',
      dataIndex: 'price',
      key: 'price',
      render: (text) => `$${text}`,
    },
    {
      title: 'Line total',
      key: 'lineTotal',
      render: (_, record) => `$${record.price * record.quantity}`,
    },
  ];

  const data = order.OrderItems.map(item => ({
    key: item.Product.id,
    name: item.Product.name,
    quantity: item.quantity,
    price: item.price,
  }));

  return (
    <Card
      ref={ref}
      className="invoice-card"
      bordered={false}
      style={{ width: '100%', maxWidth: '900px', margin: 'auto' }}
    >
      <Title level={1} className="text-center mb-4">INVOICE</Title>

      <Row gutter={16}>
        <Col span={8}>
          <div>
            <Text strong>Issued:</Text> 01 Aug, 2023
            <br />
            <Text strong>Due:</Text> 15 Aug, 2023
          </div>
        </Col>
        <Col span={8}>
          <div>
            <Text strong>Billed to:</Text>
            <br />
            Company Name
            <br />
            Company address
            <br />
            City, Country - 00000
            <br />
            +0 (000) 123-4567
          </div>
        </Col>
        <Col span={8}>
          <div>
            <Text strong>From:</Text>
            <br />
            Panda, Inc
            <br />
            Business address
            <br />
            City, State, IN - 000 000
            <br />
            TAX ID 00XXXXX1234XXX
          </div>
        </Col>
      </Row>

      <Divider />

      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        size="middle"
        style={{ marginBottom: '20px' }}
      />

      <Row justify="end" style={{ marginBottom: '20px' }}>
        <Col span={6}>
          <div>
            <Text strong>Subtotal:</Text> ${order.totalAmount}
          </div>
          <div>
            <Text strong>Tax (0%):</Text> $0.00
          </div>
          <div>
            <Text strong>Total:</Text> ${order.totalAmount}
          </div>
          <div>
            <Text strong>Amount due:</Text> ${order.totalAmount}
          </div>
        </Col>
      </Row>

      <Divider />

      <div className="text-center">
        <p>Thank you for the business!</p>
        <p>Please pay within 15 days of receiving this invoice.</p>
        <p>Digital Product Designer, IN</p>
        <p>+91 00000 00000</p>
        <p>hello@email.com</p>
      </div>
    </Card>
  );
});

Invoice.propTypes = {
  order: PropTypes.shape({
    OrderItems: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        price: PropTypes.number,
        Product: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
          description: PropTypes.string,
          imageUrl: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
    totalAmount: PropTypes.number,
  }).isRequired,
};

export default Invoice;
