import React from 'react';
import { Button, Label, FormGroup, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { message } from 'antd';

const LoginFormik = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();

  const initialValues = {
    email: '',
    password: '',
    role: 'EMPLOYEE',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .required('Password is required'),
      role: Yup.string().required('Role is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FOS_CLIENT_API}/admin/login`,
        values,
      );

      if (response.data.isActive === false) {
        message.error('Your account is inactive');
      } else {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('http://localhost:3000', response.data.role);
        console.log(response.data.role);
        setIsLoggedIn(true); // Update login status
        navigate('/dashboards/modern');
      }
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data : error.message);
      message.error(error.response ? error.response.data.message : error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="loginBox">
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="12" className="loginContainer">
            <Card>
              <CardBody className="p-4 m-1">
                <center className="mb-3">
                  <h1 className="mb-0">Login</h1>
                </center>
                {/* <small className="pb-4 d-block">
                  Do not have an account?{' '}
                  <Link className="text-info" to="/auth/registerformik">
                    Sign Up
                  </Link>
                </small> */}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Field
                          name="email"
                          type="text"
                          className={`form-control${
                            errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Field
                          name="password"
                          type="password"
                          className={`form-control${
                            errors.password && touched.password ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                      {/* <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Field
                          as="select"
                          name="role"
                          required
                          className={`form-control${
                            errors.role && touched.role ? ' is-invalid' : ''
                          }`}
                        >
                          <option value="">-Select a role-</option>
                          <option value="ADMIN" label="Admin" />
                          <option value="EMPLOYEE" label="Employee" />
                     
                        </Field>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup> */}

                      <FormGroup className="form-check d-flex" inline>
                        <Label check className="d-flex">
                          <div>
                            <input type="checkbox"></input>
                          </div>
                          Remember me
                        </Label>
                        {/* <Link className="ms-auto text-decoration-none" to="/auth/forgotPwd">
                          <small className="text-info">Forgot Pwd?</small>
                        </Link> */}
                      </FormGroup>
                      <FormGroup>
                        <Button type="submit" color="info" className="me-2">
                          Login
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
LoginFormik.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default LoginFormik;
