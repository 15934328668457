import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Button, FormGroup, Label, Modal, Input } from 'reactstrap';
import Form from 'react-validation/build/form';
import { message, Popconfirm } from 'antd';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ComponentCard from '../../components/ComponentCard';

const Category = () => {
  const [categoryName, setCatName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('http://localhost:3000');
    setUserRole(role);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/category`)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => {
        console.error(err);
        alert('Error fetching data');
      });
  }, [refresh]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_FOS_CLIENT_API}/category`, { categoryName })
      .then((res) => {
        setRefresh(!refresh);
        console.log(res);
        setCatName(''); 
        message.success('Category created successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditSubmit = () => {
    if (!editCategoryId) {
      console.error('Edit Category ID is not set');
      return;
    }
    axios
      .put(`${process.env.REACT_APP_FOS_CLIENT_API}/category/${editCategoryId}`, { categoryName: editCategoryName })
      .then((res) => {
        setTableData((prevData) =>
          prevData.map((category) =>
            category.categoryId === editCategoryId
              ? { ...category, categoryName: editCategoryName }
              : category
          )
        );
        console.log('Category updated:', res);
        setEditModalVisible(false);
        message.success('Category updated successfully');
      })
      .catch((err) => {
        console.error('Error updating category:', err);
      });
  };

  const handleDelete = (categoryId) => {
    if (!categoryId) {
      console.error('Delete Category ID is not set');
      return;
    }
    axios
      .delete(`${process.env.REACT_APP_FOS_CLIENT_API}/category/${categoryId}`)
      .then((res) => {
        setTableData((prevData) => prevData.filter((category) => category.categoryId !== categoryId));
        console.log('Category deleted:', res);
        message.success('Category deleted successfully');
      })
      .catch((err) => {
        message.error('Name Already Exists');
        console.error('Error deleting category:', err);
      });
  };

  const openEditModal = (category) => {
    if (!category || !category.categoryId) {
      console.error('Invalid category data for editing:', category);
      return;
    }
    setEditCategoryId(category.categoryId);
    setEditCategoryName(category.categoryName);
    setEditModalVisible(true);
  };

  const numberFormatter = (cell, row, enumObject, index) => (index + 1);

  return (
    <>
      <Row>
        <Col sm="12">
          <ComponentCard title="Form Validation">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label className="control-Label" htmlFor="category">
                  Category :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={(e) => setCatName(e.target.value)}
                    type="text"
                    className="form-control"
                    value={categoryName}
                    maxLength={30}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Button className="button btn-info" type="submit">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </ComponentCard>

          <ComponentCard title="Bootstrap DataTable">
            <BootstrapTable
              hover
              condensed
              search
              data={tableData}
              pagination
              tableHeaderClass="mb-0"
            >
              <TableHeaderColumn
                width="100"
                dataField="categoryId"
                isKey
                dataFormat={numberFormatter}
                className="text-dark bg-light"
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn className="text-dark bg-light" width="100" dataField="categoryName">
                Name
              </TableHeaderColumn>
              <TableHeaderColumn className="text-dark bg-light" width="100" dataField="actions" dataFormat={(cell, row) => (
                <>
                  <Button className="btn-sm btn-warning" onClick={() => openEditModal(row)}>Edit</Button>
                  {userRole !== 'EMPLOYEE' && (
                    <Popconfirm
                      title="Are you sure to delete this category?"
                      onConfirm={() => handleDelete(row.categoryId)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="btn-sm btn-danger" style={{ marginLeft: '10px' }}>Delete</Button>
                    </Popconfirm>
                  )}
                </>
              )}>
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          </ComponentCard>

          <Modal isOpen={editModalVisible} toggle={() => setEditModalVisible(!editModalVisible)}>
            <div className="modal-header">
              <h5 className="modal-title">Edit Category</h5>
              <button type="button" className="close" aria-label="Close" onClick={() => setEditModalVisible(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FormGroup>
                <Label className="control-Label" htmlFor="category">Category :</Label>
                <div className="mb-1">
                  <Input
                    onChange={(e) => setEditCategoryName(e.target.value)}
                    type="text"
                    className="form-control"
                    value={editCategoryName}
                    required
                  />
                </div>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button className="button btn-info" onClick={handleEditSubmit}>Save</Button>
              <Button className="button btn-secondary" onClick={() => setEditModalVisible(false)}>Cancel</Button>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default Category;
