import { Input, Space, Table, Button, Modal, List, message } from 'antd';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ProductDetails from '../form-validation/ProductDetails';

function OrderAll() {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders`);
                const filtered = response.data
                    .filter(order => order.status === 'pending')
                    .sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                setFilteredOrders(filtered);
                setOrders(filtered);  // Update orders state here
            } catch (error) {
                console.error('Error fetching orders data:', error);
            }
        };
        fetchData();
    }, []);
    

    // Handle search functionality
    const handleSearch = (value) => {
        setSearchText(value);
        const lowercasedValue = value.toLowerCase();
        const filtered = orders.filter(order =>
            (order.ShippingAddress.email.toLowerCase().includes(lowercasedValue) ||
            order.ShippingAddress.Phone.includes(lowercasedValue)) &&
            order.status === 'pending'
        );
        setFilteredOrders(filtered);
    };

    // Handle modal visibility
    const showModal = async (orderId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders/${orderId}`);
            setSelectedOrder(response.data);
            setIsModalVisible(true);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Handle approve order
    const handleApprove = (orderId) => {
        Modal.confirm({
            title: 'Are you sure to approve this order?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                try {
                    await axios.put(`${process.env.REACT_APP_FOS_CLIENT_API}/orders/${orderId}`, { status: 'OrderAccepted' });
                    message.success('Order status updated to delivery');

                    // Update the state to reflect the changes
                    setOrders(prevOrders => prevOrders.filter(order => order.orderId !== orderId));
                    setFilteredOrders(prevFiltered => prevFiltered.filter(order => order.orderId !== orderId));
                } catch (error) {
                    console.error('Error updating order status:', error);
                    message.error('Failed to update order status');
                }
            },
        });
    };

    // Define columns for the Ant Design Table
    const columns = [
        {
            title: 'No.',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: ['User', 'name'],
            key: 'name',
        },
        {
            title: 'Phone',
            dataIndex: ['User', 'phone'],
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: ['ShippingAddress', 'email'],
            key: 'email',
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentmtd',
            key: 'paymentmtd',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<EyeOutlined />}
                        onClick={() => showModal(record.orderId)}
                    />
                    <Button
                        type="primary"
                        icon={<CheckOutlined />}
                        onClick={() => handleApprove(record.orderId)}
                    />
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Space style={{ marginBottom: 16 }}>
                <Input.Search
                    placeholder="Search by email or phone"
                    enterButton
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                />
            </Space>
            <Table
                dataSource={filteredOrders}
                columns={columns}
                className='rounded'
                rowKey="orderId"
            />
            <Modal
                className='w-75'
                title="Order Details"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {selectedOrder && (
                    <div>
                        <p><strong>Email:</strong> {selectedOrder.ShippingAddress.email}</p>
                        <p><strong>Address:</strong> {selectedOrder.ShippingAddress.address}</p>
                        <p><strong>Phone:</strong> {selectedOrder.ShippingAddress.Phone}</p>
                        <p><strong>Total Amount:</strong> {selectedOrder.totalAmount}</p>
                        <p><strong>Payment Method:</strong> {selectedOrder.paymentmtd}</p>
                        <p><strong>Order Date:</strong> {new Date(selectedOrder.orderDate).toLocaleString()}</p>

                        <h3>Order Items:</h3>
                        <List
                            dataSource={selectedOrder.OrderItems}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.Product.name}
                                    />
                                    <ProductDetails product={item.Product.description} />
                                    <div>
                                        <p><strong>Quantity:</strong> {item.quantity}</p>
                                        <p><strong>Price:</strong> {item.price}</p>
                                        <img
                                            src={item.Product.imageUrl[0]}
                                            alt={item.Product.name}
                                            style={{ width: '100px' }}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default OrderAll;
