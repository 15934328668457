import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormGroup, Label, Modal, Input } from 'reactstrap';
import Form from 'react-validation/build/form';
import axios from 'axios';
import { message, Popconfirm } from 'antd';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { useDispatch } from 'react-redux';
import ComponentCard from '../../components/ComponentCard';
import { setBrands } from '../../store/Slices/brand-slice';

const Brand = () => {
  const [data, setData] = useState([]);
  const [catdata, catsetData] = useState([]);
  const [categoryId, setcategoryId] = useState('');
  const [brandName, setname] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editBrandId, setEditBrandId] = useState(null);
  const [editBrandName, setEditBrandName] = useState('');
  const [editCategoryId, setEditCategoryId] = useState('');
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('http://localhost:3000');
    setUserRole(role);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/brands`)
      .then((res) => {
        setData(res.data);
        dispatch(setBrands(res.data));
        console.log('Brands data:', res.data);
      })
      .catch((err) => {
        console.error('Error fetching brands:', err);
      });
  }, [refresh]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/category`)
      .then((res) => {
        catsetData(res.data);
        console.log('Categories data:', res.data);
      })
      .catch((err) => {
        console.error('Error fetching categories:', err);
      });
  }, [refresh]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_FOS_CLIENT_API}/brands`, { brandName, categoryId })
      .then((res) => {
        setRefresh(!refresh);
        console.log('Brand created:', res);
        setname('');
        setcategoryId('');
        message.success('Brand created successfully');
      })
      .catch((err) => {
        message.error('Name Already Exists');
        console.error('Error creating brand:', err);
      });
    console.log('Category ID on submit:', categoryId);
  };

  const handleEditSubmit = () => {
    if (!editBrandId) {
      console.error('Edit Brand ID is not set');
      return;
    }
    axios
      .put(`${process.env.REACT_APP_FOS_CLIENT_API}/brands/${editBrandId}`, {
        brandName: editBrandName,
        categoryId: editCategoryId,
      })
      .then((res) => {
        setData((prevData) =>
          prevData.map((brand) =>
            brand.brandId === editBrandId
              ? { ...brand, brandName: editBrandName, categoryId: editCategoryId }
              : brand
          )
        );
        console.log('Brand updated:', res);
        setEditModalVisible(false);
        message.success('Brand updated successfully');
      })
      .catch((err) => {
        console.error('Error updating brand:', err);
      });
  };

  const handleDelete = (brandId) => {
    if (!brandId) {
      console.error('Delete Brand ID is not set');
      return;
    }
    axios
      .delete(`${process.env.REACT_APP_FOS_CLIENT_API}/brands/${brandId}`)
      .then((res) => {
        setRefresh(!refresh);
        console.log('Brand deleted:', res);
        message.success('Brand deleted successfully');
      })
      .catch((err) => {
        console.error('Error deleting brand:', err);
      });
  };

  const openEditModal = (brand) => {
    if (!brand || !brand.brandId) {
      console.error('Invalid brand data for editing:', brand);
      return;
    }
    setEditBrandId(brand.brandId);
    setEditBrandName(brand.brandName);
    setEditCategoryId(brand.categoryId);
    setEditModalVisible(true);
  };

  const numberFormatter = (cell, row, enumObject, index) => (index + 1);

  return (
    <>
      <Row>
        <Col sm="12">
          <ComponentCard title="Form Validation">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Brand :
                </Label>
                <div className="mb-1">
                  <input
                    onChange={(e) => setname(e.target.value)}
                    type="text"
                    className="form-control"
                    value={brandName}
                    required
                    max={100}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="control-Label" htmlFor="title">
                  Category :
                </Label>
                <div className="mb-1">
                  <select
                    onChange={(e) => setcategoryId(e.target.value)}
                    value={categoryId}
                    className="form-control"
                  >
                    <option>Choose Category</option>
                    {catdata.map((m) => (
                      <option key={m.categoryId} value={m.categoryId}>
                        {m.categoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </FormGroup>
              <FormGroup>
                <Button className="button btn-info" type="submit">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </ComponentCard>

          <ComponentCard title="Bootstrap DataTable">
            <BootstrapTable
              hover
              condensed
              search
              data={data}
              pagination
              tableHeaderClass="mb-0"
            >
              <TableHeaderColumn
                width="100"
                dataFormat={numberFormatter}
                dataField="categoryId"
                isKey
                className="text-dark bg-light"
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn
                className="text-dark bg-light"
                width="100"
                dataField="brandName"
              >
                Name
              </TableHeaderColumn>
              <TableHeaderColumn
                className="text-dark bg-light"
                width="100"
                dataField="actions"
                dataFormat={(cell, row) => (
                  <>
                    <Button
                      className="btn-sm btn-warning"
                      onClick={() => openEditModal(row)}
                    >
                      Edit
                    </Button>
                    {userRole !== 'EMPLOYEE' && (
                      <Popconfirm
                        title="Are you sure to delete this brand?"
                        onConfirm={() => handleDelete(row.brandId)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="btn-sm btn-danger"
                          style={{ marginLeft: '10px' }}
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                    )}
                  </>
                )}
              >
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          </ComponentCard>

          <Modal
            isOpen={editModalVisible}
            toggle={() => setEditModalVisible(!editModalVisible)}
          >
            <div className="modal-header">
              <h5 className="modal-title">Edit Brand</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => setEditModalVisible(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FormGroup>
                <Label className="control-Label" htmlFor="age">
                  Brand :
                </Label>
                <div className="mb-1">
                  <Input
                    onChange={(e) => setEditBrandName(e.target.value)}
                    type="text"
                    className="form-control"
                    value={editBrandName}
                    required
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label className="control-Label" htmlFor="title">
                  Category :
                </Label>
                <div className="mb-1">
                  <select
                    onChange={(e) => setEditCategoryId(e.target.value)}
                    value={editCategoryId}
                    className="form-control"
                  >
                    <option>Choose Category</option>
                    {catdata.map((m) => (
                      <option key={m.categoryId} value={m.categoryId}>
                        {m.categoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <Button className="button btn-info" onClick={handleEditSubmit}>
                Save Changes
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={() => setEditModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default Brand;
